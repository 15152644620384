<template>
    <Modal
        v-model="showModal"
        title="WARNING"
        footer-hide
        class="delete-request-modal-wrapper"
        :closable="false"
        :mask-closable="false"
    >
        <div>
            <br />
            <p style="text-align: justify">
                Are you sure you want {{ message }} this request? This action cannot be undone.
            </p>
            <br />

            <Row class="confirmation-actions md-margin-top">
                <Col :xs="24" :sm="6">
                    <Button @click="handleConfirmation(false)"> CANCEL </Button>
                </Col>
                <Col :xs="24" :sm="6">
                    <Button class="pt-btn-3" :loading="isLoading" @click="handleConfirmation(true)"> YES </Button>
                </Col>
            </Row>
        </div>
    </Modal>
</template>

<script>
import { deleteTimeDispute, getRequestRecordByUserAndAttendanceId } from '@/services/requests.services';
import { timeConversion } from '../../../../../utility/helper';
import moment from 'moment/moment';

export default {
    data() {
        return {
            requestId: null,
            attendanceId: null,
            userId: null,
            message: '',
            showModal: false,
            isLoading: false,
        };
    },

    methods: {
        async handleGetRequestRecordByAttendanceId(data) {
            return await getRequestRecordByUserAndAttendanceId(this.attendanceId, this.userId, {
                requestedStart: timeConversion(moment.utc(data.createdAt).format('DD-MMM-YYYY'), '00:00'),
            });
        },
        async openModal(data, mensahe) {
            this.attendanceId = mensahe.includes('withdraw') ? data._id : data.attendanceLog._id;
            this.userId = data.user._id;
            if (mensahe.includes('withdraw')) {
                const result = await this.handleGetRequestRecordByAttendanceId(data);
                console.log('res: ', result);
                this.requestId = result.data[0]._id;
            } else {
                this.requestId = data._id;
            }
            this.message = mensahe;
            this.showModal = true;
        },
        handleConfirmation(status) {
            this.isLoading = true;
            if (status) {
                deleteTimeDispute(this.requestId)
                    .then(() => {
                        this.$Message.success(`Request time dispute ${this.message} successfully`);
                        this.$emit('create');
                        this.isLoading = false;
                        this.showModal = false;
                    })
                    .catch(() => {
                        this.$Message.error(`Failed to ${this.message} request time dispute`);
                        this.isLoading = false;
                    });
            } else {
                this.isLoading = false;
                this.showModal = false;
            }
        },
    },
};
</script>

<style>
.delete-request-modal-wrapper.v-transfer-dom .ivu-modal-body,
.ivu-modal-confirm {
    padding: 16px;
}
.delete-request-modal-wrapper .ivu-modal-header {
    background: none !important;
    border: none !important;
}
.delete-request-modal-wrapper .ivu-modal-header .ivu-modal-header-inner {
    color: brown !important;
}
.confirmation-actions {
    display: flex;
    align-content: space-between;
    justify-content: center;
}
</style>
