<template>
    <div>
        <Modal
            v-model="showModal"
            draggable
            sticky
            title="View Attendance Log"
            width="600"
            no-footer
            :closable="closable"
            class="attendanceTimelog"
            :before-close="handleReset"
        >
            <Row justify="start" align="top">
                <Col span="15">
                    <div v-if="details">
                        <Row>
                            <Col span="8">
                                <span>Date</span>
                            </Col>
                            <Col span="8">Status</Col>
                        </Row>
                        <Row style="margin-bottom: 50px">
                            <Col span="8">
                                <span>{{ details.dateRequested }}</span>
                            </Col>
                            <Col span="8"
                                ><div :class="details.className">{{ details.statLabel }}</div></Col
                            >
                        </Row>
                        <Row>
                            <Col span="8"><span>Shift logs</span></Col>
                        </Row>
                        <Row style="margin-bottom: 50px">
                            <ShiftLogsListTable
                                :shift-log-id="shiftLogId"
                                @close-view-modal="handleCancel"
                                @refresh="handleRefreshList"
                            />
                        </Row>
                        <Row>
                            <Col span="8"><span>Project logs</span></Col>
                        </Row>
                        <Row style="margin-bottom: 50px">
                            <ProjectTimeLogsListTable
                                :project-user-id="projectUserId"
                                :shift-date="details.createdAt"
                            />
                        </Row>
                        <div class="text-left">
                            <Button class="pt-btn-2" @click="handleCancel"> CLOSE </Button>
                        </div>
                    </div>
                    <p v-else class="text-center" style="width: 600px">No Data</p>
                </Col>
            </Row>
        </Modal>
    </div>
</template>
<script>
import moment from 'moment';
import ShiftLogsListTable from '@/components/Attendance/Dev/ShiftLogsListTable';
import ProjectTimeLogsListTable from '@/components/Attendance/Dev/ProjectTimeLogsListTable';
export default {
    components: { ShiftLogsListTable, ProjectTimeLogsListTable },
    data() {
        return {
            isLoading: false,
            details: null,
            showModal: false,
            closable: false,
            shiftLogId: null,
            projectUserId: null,
        };
    },
    methods: {
        openModal(data) {
            const status = this.handleStatusLabel(data);
            this.details = {
                dateRequested: moment(data.createdAt).utc().format('DD-MMM-yyyy'),
                className: status.className,
                statLabel: status.label,
                createdAt: data.createdAt,
            };
            this.shiftLogId = data._id;
            this.projectUserId = data.user._id;
            this.showModal = true;
        },

        handleStatusLabel(data) {
            const status = data.status;
            let label = '',
                className = 'statusLabel';
            const upperCaseStats = status.toString().toUpperCase();
            switch (status.toString().trim()) {
                case 'regular':
                    label = upperCaseStats;
                    className = `${className} success`;
                    break;
                case 'snonworkholiday':
                    label = 'SPECIAL NON-WORKING HOLIDAY';
                    className = `${className} success`;
                    break;
                case 'sworkholiday':
                    label = 'SPECIAL WORKING HOLIDAY';
                    className = `${className} success`;
                    break;
                case 'regholiday':
                    label = 'REGULAR HOLIDAY';
                    className = `${className} secondary`;
                    break;
                case 'rest':
                    label = 'REST DAY';
                    className = `${className} secondary`;
                    break;
                case 'absent':
                    label = upperCaseStats;
                    className = `${className} danger`;
                    break;
                case 'late':
                    label = upperCaseStats;
                    className = `${className} warning`;
                    break;
                case 'leave':
                    label = `ON ${upperCaseStats}`;
                    className = `${className} primary`;
                    break;
                case 'undertime':
                    label = upperCaseStats;
                    className = `${className} warning`;
                    break;
                case 'timedispute':
                    label = 'TIME DISPUTE';
                    className = `${className} warning`;
                    break;
                default:
                    break;
            }
            return { label, className };
        },

        handleCancel() {
            this.showModal = false;
        },

        handleConfirm() {
            this.showModal = false;
        },
        handleReset() {
            this.showModal = false;
        },

        handleRefreshList() {
            this.$emit('reload');
        },
    },
};
</script>
<style>
.attendanceTimelog .ivu-modal-body {
    padding: 16px;
}
.attendanceTimelog .ivu-modal-footer {
    display: none;
}
.attendanceTimelog .ivu-spin-main {
    justify-content: center;
    display: flex;
}
</style>
