<template>
    <div>
        <Editor v-model="messages" :api-key="tinymceApiKey" :init="options" />
    </div>
</template>

<script>
/*global process*/
/*eslint no-undef: "error"*/

import Editor from '@tinymce/tinymce-vue';

export default {
    components: {
        Editor,
    },
    props: {
        value: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            messages: '',
            tinymceApiKey: process.env.VUE_APP_TINYMCE_API_KEY,
            options: {
                branding: false,
                height: 385,
                menubar: false,
                fullpage_default_font_size: '10px',
                force_br_newlines: false,
                force_p_newlines: false,
                forced_root_block: '',
                apply_source_formatting: false,
                valid_children: '+body[style],-body[div],p[strong|a|#text]',
                remove_trailing_brs: true,
                remove_linebreaks: true,
                remove_redundant_brs: true,
                valid_child_elements:
                    'h1/h2/h3/h4/h5/h6/a[%itrans_na],table[thead|tbody|tfoot|tr|td],strong/b/p/div/ol/ul/li/em/i/td[%itrans|#text],body[%btrans|#text]',
                plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                    'undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help | preview',
            },
        };
    },
    watch: {
        messages(val) {
            this.$emit('messages', val);
        },
    },
};
</script>
