import { axiosInstance, getAuthorizationHeaders } from './base';

/*Employee Api Endpoints*/
const roleUrlString = localStorage.getItem('mainRole') === 'pm' ? `pm/attendancelogs` : `attendancelogs`;
export function getAttendanceByUser(userId) {
    const urlString = `/today/per/user/${userId}`;
    return axiosInstance.get(roleUrlString + urlString, getAuthorizationHeaders());
}

export function updateClockOut(id, data) {
    const urlString = `/${id}/clock-out`;
    return axiosInstance.patch(roleUrlString + urlString, data, getAuthorizationHeaders());
}

export function postClockIn(data) {
    const urlString = `/clock-in`;
    return axiosInstance.post(roleUrlString + urlString, data, getAuthorizationHeaders());
}

export function getPaginatedAttendanceRecords(userId, filter, page = 1, limit = 1000) {
    const urlString = `/paginated/${userId}/records`;
    return axiosInstance.get(roleUrlString + urlString, {
        params: { filter: filter, limit: limit, page: page, sort: { clockInTime: 1 } },
        ...getAuthorizationHeaders(),
    });
}

export function getAttendanceRecordById(id) {
    const urlString = `/${id}`;
    return axiosInstance.get(roleUrlString + urlString, getAuthorizationHeaders());
}
/*Admin Api Endpoints*/
export function getAdminPaginatedAttendanceRecords(filter, page = 1, limit = 1000) {
    const urlString = `admin/${roleUrlString}/get-paginated-aggregated-attendance-records`;
    return axiosInstance.get(urlString, {
        params: { filter: filter, limit: limit, page: page, sort: { clockInTime: 1 } },
        ...getAuthorizationHeaders(),
    });
}
