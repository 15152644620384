<template>
    <div>
        <div>
            <Table :columns="tblColumns" :data="tblData" size="small" :loading="isLoading" class="shiftLogsList" />
        </div>
        <CreateRequestModal ref="CreateRequest" @create="handleRefresh" />
        <ViewRequestModal ref="ViewRequest" @create="handleRefresh" />
        <UpdateRequestModal ref="UpdateRequest" @create="handleRefresh" />
        <DeleteRequestModal ref="DeleteRequest" @create="handleRefresh" />
    </div>
</template>

<script>
import { getAttendanceRecordById } from '@/services/attendancelogs.services';
import CreateRequestModal from '@/components/Requests/Dev/TimeDispute/CreateRequestModal';
import ViewRequestModal from '@/components/Requests/Dev/TimeDispute/ViewRequestModal';
import UpdateRequestModal from '@/components/Requests/Dev/TimeDispute/UpdateRequestModal';
import DeleteRequestModal from '@/components/Requests/Dev/TimeDispute/DeleteRequestModal';
import moment from 'moment';
export default {
    components: { CreateRequestModal, ViewRequestModal, UpdateRequestModal, DeleteRequestModal },
    props: {
        shiftLogId: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            isLoading: false,
            tblColumns: [
                {
                    title: 'Clock In',
                    key: 'clockInTime',
                    width: 100,
                    render: (h, params) => {
                        return params.row.clockInTime
                            ? h('div', moment(params.row.clockInTime).utc().format('hh:mm A'))
                            : h('div', { class: 'hyphenFont' }, '-');
                    },
                },
                {
                    title: 'Clock Out',
                    key: 'clockOutTime',
                    width: 100,
                    render: (h, params) => {
                        return params.row.clockOutTime
                            ? h('div', moment(params.row.clockOutTime).utc().format('hh:mm A'))
                            : h('div', { class: 'hyphenFont' }, '-');
                    },
                },
                {
                    title: 'Total Hours',
                    key: 'totalDailyHrs',
                    width: 150,
                    render: (h, params) => {
                        return params.row.totalDailyHrs
                            ? h('div', Number(params.row.totalDailyHrs).toFixed(0))
                            : h('div', { class: 'hyphenFont' }, '-');
                    },
                },
                {
                    title: 'Actions',
                    width: 200,
                    align: 'left',
                    render: (h, params) => {
                        return h(
                            'div',
                            !this.isStatusTimeDispute
                                ? [
                                      h('Tooltip', { props: { placement: 'top', content: 'View' } }, [
                                          h(
                                              'Button',
                                              { class: `${this.mainRole ? 'pt-btn-3' : 'hide'}` },
                                              [
                                                  h('Icon', {
                                                      props: {
                                                          type: 'md-eye',
                                                          size: '25',
                                                      },
                                                      on: {
                                                          click: () => {
                                                              this.handleCloseViewModal();
                                                              this.$refs.ViewRequest.openModal(params.row, true);
                                                              console.log(params.row);
                                                          },
                                                      },
                                                  }),
                                              ],
                                              'view'
                                          ),
                                      ]),
                                      h('Tooltip', { props: { placement: 'top', content: 'Request Time Dispute' } }, [
                                          h(
                                              'Button',
                                              { class: `${this.mainRole ? 'hide' : 'pt-btn-6'}` },
                                              [
                                                  h('Icon', {
                                                      props: {
                                                          type: 'md-arrow-round-forward',
                                                          size: '25',
                                                      },
                                                      on: {
                                                          click: () => {
                                                              this.handleCloseViewModal();
                                                              this.$refs.CreateRequest.openModal(params.row, true);
                                                              console.log(params.row);
                                                          },
                                                      },
                                                  }),
                                              ],
                                              'add'
                                          ),
                                      ]),
                                  ]
                                : [
                                      h('Tooltip', { props: { placement: 'top', content: 'View' } }, [
                                          h(
                                              'Button',
                                              { class: 'pt-btn-3' },
                                              [
                                                  h('Icon', {
                                                      props: {
                                                          type: 'md-eye',
                                                          size: '25',
                                                      },
                                                      on: {
                                                          click: () => {
                                                              this.handleCloseViewModal();
                                                              this.$refs.ViewRequest.openModal(params.row, true);
                                                              console.log(params.row);
                                                          },
                                                      },
                                                  }),
                                              ],
                                              'view'
                                          ),
                                      ]),
                                      h('Tooltip', { props: { placement: 'top', content: 'Edit' } }, [
                                          h(
                                              'Button',
                                              { class: `${this.mainRole ? 'hide' : 'pt-btn-6'}` },
                                              [
                                                  h('Icon', {
                                                      props: {
                                                          type: 'md-create',
                                                          size: '25',
                                                      },
                                                      on: {
                                                          click: () => {
                                                              this.handleCloseViewModal();
                                                              this.$refs.UpdateRequest.openModal(params.row, true);
                                                              console.log(params.row);
                                                          },
                                                      },
                                                  }),
                                              ],
                                              'edit'
                                          ),
                                      ]),
                                      h('Tooltip', { props: { placement: 'top', content: 'Withdraw time dispute' } }, [
                                          h(
                                              'Button',
                                              { class: `${this.mainRole ? 'hide' : 'pt-btn-1'}` },
                                              [
                                                  h('Icon', {
                                                      props: {
                                                          type: 'md-arrow-round-forward',
                                                          size: '25',
                                                      },
                                                      on: {
                                                          click: () => {
                                                              this.handleCloseViewModal();
                                                              this.$refs.DeleteRequest.openModal(
                                                                  params.row,
                                                                  'withdraw'
                                                              );
                                                              console.log(params.row);
                                                          },
                                                      },
                                                  }),
                                              ],
                                              'withdraw'
                                          ),
                                      ]),
                                  ]
                        );
                    },
                },
            ],
            tblData: [],
            status: null,
            mainRole: localStorage.getItem('mainRole') === 'admin',
        };
    },

    computed: {
        isStatusTimeDispute() {
            return this.status.includes('timedispute');
        },
    },

    watch: {
        shiftLogId(data) {
            this.populateShiftLogs(data);
        },
    },

    created() {
        this.populateShiftLogs(this.shiftLogId);
    },

    methods: {
        async populateShiftLogs(id) {
            this.isLoading = true;
            this.tblData = [];
            await getAttendanceRecordById(id)
                .then((res) => {
                    this.tblData = [res.data];
                    this.status = res.data.status;
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get shifts logs');
                    this.isLoading = false;
                    this.tblData = [];
                });
        },
        handleCloseViewModal() {
            this.$emit('close-view-modal');
        },
        handleRefresh() {
            this.populateShiftLogs(this.shiftLogId);
            this.$emit('refresh');
        },
    },
};
</script>

<style lang="scss">
ul.pagination.ivu-page {
    text-align: end;
}
.shiftLogsList button {
    width: 25px;
    margin-left: 10px;
}
.shiftLogsList i.ivu-icon {
    margin-left: -12.5px;
}
.hide {
    display: none;
}
</style>
