<template>
    <div>
        <div>
            <Table
                :columns="tblColumns"
                :data="tblData"
                size="small"
                :loading="isLoading"
                class="projectTimeLogsList"
            />
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { getTimelogsByUser } from '@/services/timelogs.services';
export default {
    props: {
        projectUserId: {
            type: String,
            default: null,
        },
        shiftDate: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            isLoading: false,
            tblColumns: [
                {
                    title: 'Project Name',
                    key: 'projectName',
                    width: 140,
                    render: (h, params) => {
                        return h('div', params.row.project.name);
                    },
                },
                {
                    title: 'Start Time',
                    key: 'startTime',
                    //fixed: 'left',
                    width: 100,
                    render: (h, params) => {
                        return params.row.startTime
                            ? h('div', moment(params.row.startTime).utc().format('hh:mm A'))
                            : h('div', { class: 'hyphenFont' }, '-');
                    },
                },
                {
                    title: 'End Time',
                    key: 'endTime',
                    width: 100,
                    render: (h, params) => {
                        return params.row.endTime
                            ? h('div', moment(params.row.endTime).utc().format('hh:mm A'))
                            : h('div', { class: 'hyphenFont' }, '-');
                    },
                },
                {
                    title: 'Total Hours',
                    key: 'rowDaily',
                    width: 110,
                    render: (h, params) => {
                        return params.row.rowDaily
                            ? h('div', Number(params.row.rowDaily).toFixed(0))
                            : h('div', { class: 'hyphenFont' }, '-');
                    },
                },
                {
                    title: 'Actions',
                    width: 100,
                    align: 'center',
                    render: (h, params) => {
                        return h('div', [
                            h('Tooltip', { props: { placement: 'top', content: 'View' } }, [
                                h(
                                    'Button',
                                    { class: 'pt-btn-3' },
                                    [
                                        h('Icon', {
                                            props: {
                                                type: 'md-eye',
                                                size: '25',
                                            },
                                            on: {
                                                click: () => {
                                                    // this.$refs.ViewAttendance.openModal(params.row);
                                                    console.log(params.row);
                                                },
                                            },
                                        }),
                                    ],
                                    'view'
                                ),
                            ]),
                        ]);
                    },
                },
            ],
            tblData: [],
        };
    },

    watch: {
        shiftDate(date) {
            this.populateProjectTimeLogs(this.projectUserId, date);
        },
        projectUserId(data) {
            this.populateProjectTimeLogs(data, this.shiftDate);
        },
    },

    mounted() {
        this.populateProjectTimeLogs(this.projectUserId, this.shiftDate);
    },

    methods: {
        async populateProjectTimeLogs(userId, date) {
            this.isLoading = true;
            this.tblData = [];
            getTimelogsByUser(userId)
                .then((res) => {
                    if (res.data.length) {
                        if (moment().isBefore(moment('08:00:00', 'HH:mm:ss'), 'hours')) {
                            this.tblData = res.data.filter((x) => moment(x.startTime).isSame(moment(date), 'day'));
                        } else {
                            this.tblData = res.data.filter((x) =>
                                moment(x.startTime).utc().isSame(moment(date).utc(), 'day')
                            );
                        }
                    } else {
                        this.tblData = [];
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get project timelogs by user');
                    this.tblData = [];
                    this.isLoading = false;
                });
        },
    },
};
</script>

<style lang="scss">
ul.pagination.ivu-page {
    text-align: end;
}
.projectTimeLogsList button.pt-btn-3 {
    width: 25px;
}
.projectTimeLogsList i.ivu-icon {
    margin-left: -12.5px;
}
</style>
