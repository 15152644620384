import { axiosInstance } from './base';

export function getTimelogs(params = null) {
    let initialUrl = null;
    if (params) {
        initialUrl = `timelogs?${params}`;
    } else {
        initialUrl = `timelogs`;
    }
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`${initialUrl}`, { headers });
}

export function getTimelogsByProjectUser(projId, userId, params = null) {
    let initialUrl = null;
    if (params) {
        initialUrl = `timelogs/${projId}/${userId}?${params}`;
    } else {
        initialUrl = `timelogs/${projId}/${userId}`;
    }
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`${initialUrl}`, { headers });
}

export function getTimelogsByUserId(userId, params = null) {
    let initialUrl = null;
    if (params) {
        initialUrl = `timelogs/user/${userId}?${params}`;
    } else {
        initialUrl = `timelogs/user/${userId}`;
    }
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`${initialUrl}`, { headers });
}

export function getTimelogsByProjectId(projId, params = null) {
    let initialUrl = null;
    if (params) {
        initialUrl = `timelogs/project/${projId}?${params}`;
    } else {
        initialUrl = `timelogs/project/${projId}`;
    }
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`${initialUrl}`, { headers });
}

export function getTimelogsById(userId) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`timelogs/${userId}`, { headers });
}

export function updateTimelogsById(id, data) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.patch(`timelogs/${id}`, data, { headers });
}

export function postTimelogs(data) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.post(`timelogs`, data, { headers });
}

export function postTimelogsStart(data) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.post(`timelogs/start`, data, { headers });
}

export function getTimelogsByUser(userId) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`timelogs/per/user/${userId}`, { headers });
}

export function updateEndTime(id, data) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.patch(`timelogs/${id}/end`, data, { headers });
}

export function getBarTimelogsByUser(userId) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`timelogs/bar/${userId}`, { headers });
}

export function getBarTimelogsByUserv2(userId) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`timelogs/v2/bar/${userId}`, { headers });
}

export function getTimeTrackingByUser(userId) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`timelogs/today/per/user/${userId}`, { headers });
}

export function getAggregatedTimelogs(filter, page = 1, limit = 1000) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`timelogs/get-paginated-aggregated-timelogs`, {
        params: { filter: filter, limit: limit, page: page, sort: { startTime: -1 } },
        headers,
    });
}
