<template>
    <div>
        <Modal
            v-model="showModal"
            draggable
            sticky
            title="Edit Time Dispute"
            width="600"
            no-footer
            :closable="closable"
            class="updateRequest"
            :before-close="handleReset"
        >
            <Form
                ref="form"
                :model="form"
                :rules="ruleInline"
                label-position="left"
                class="update-time-dispute-wrapper"
            >
                <Row>
                    <Col span="12">
                        <FormItem label="Date" prop="createdAt">
                            <DatePicker v-model="form.createdAt" type="date" format="dd-MMM-yyyy" transfer disabled />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                        <FormItem label="Initial Start Time" prop="initialStartTime">
                            <DatePicker
                                v-model="form.initialStartTime"
                                type="datetime"
                                format="hh:mm A"
                                transfer
                                disabled
                            />
                        </FormItem>
                    </Col>
                    <Col span="8">
                        <FormItem label="Initial End Time" prop="initialEndTime">
                            <DatePicker
                                v-model="form.initialEndTime"
                                type="datetime"
                                format="hh:mm A"
                                transfer
                                disabled
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                        <FormItem label="Requested Start Time" prop="requestedStartTime">
                            <TimePicker
                                v-model="form.requestedStartTime"
                                type="time"
                                placeholder="Select request start time"
                                format="HH:mm"
                                confirm
                                transfer
                            />
                        </FormItem>
                    </Col>
                    <Col span="8">
                        <FormItem label="Requested End Time" prop="requestedEndTime">
                            <TimePicker
                                v-model="form.requestedEndTime"
                                type="time"
                                placeholder="Select request end time"
                                format="HH:mm"
                                confirm
                                transfer
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                        <FormItem label="Reason for request (optional)">
                            <Input
                                v-model="form.reason"
                                type="textarea"
                                :autosize="{ minRows: 2, maxRows: 5 }"
                                placeholder="Enter something..."
                                style="width: 400px"
                            />
                        </FormItem>
                    </Col>
                </Row>
            </Form>
            <div>
                <Button class="pt-btn-2" style="margin-right: 10px" @click="handleCancel"> CANCEL </Button>
                <Button class="pt-btn-3" :loading="isLoading" @click="handleUpdateTimeDispute"> SUBMIT </Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import moment from 'moment';
import { timeConversion } from '../../../../../utility/helper';
import { getRequestRecordByUserAndAttendanceId, updateTimeDispute } from '@/services/requests.services';

export default {
    data() {
        return {
            attendanceLogData: null,
            requestData: null,
            isLoading: false,
            closable: false,
            form: {
                id: null,
                createdAt: null,
                initialStartTime: null,
                initialEndTime: null,
                requestedStartTime: null,
                requestedEndTime: null,
                reason: '',
            },
            ruleInline: {
                requestedStartTime: [
                    {
                        required: true,
                        message: 'Please fill in start time',
                        trigger: 'change',
                    },
                ],
                requestedEndTime: [
                    {
                        required: true,
                        message: 'Please fill in end time',
                        trigger: 'change',
                    },
                ],
            },
            showModal: false,
        };
    },
    methods: {
        async handleUpdateTimeDispute() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.editTimeDispute();
                } else {
                    this.$Message.error('Fill in the required fields');
                }
            });
        },

        editTimeDispute() {
            this.isLoading = true;
            const initialDate = this.form.createdAt;
            const startTime = this.form.requestedStartTime;
            const endTime = this.form.requestedEndTime;

            const data = {
                requestedStart: moment(timeConversion(initialDate, startTime)).utc().format(),
                requestedEnd: moment(timeConversion(initialDate, endTime)).utc().format(),
                reason: this.form.reason ? this.form.reason : '',
            };
            console.log('submitted: ', data);
            updateTimeDispute(this.form.id, data)
                .then(() => {
                    this.$Message.success('Request time dispute successfully updated');
                    this.isLoading = false;
                    this.$emit('create');
                    this.showModal = false;
                    this.$refs.form.resetFields();
                })
                .catch((err) => {
                    if (
                        err.data.statusCode === 400 &&
                        err.data.message === 'Request start time value should not be ahead/or equal to the end time'
                    ) {
                        this.$Message.error('Request start time value should not be ahead/or equal to the end time');
                    } else if (
                        err.data.statusCode === 400 &&
                        err.data.message ===
                            'You`ve already requested time dispute for the specified date. Please try to update it instead.'
                    ) {
                        this.$Message.error(
                            'You`ve already requested time dispute for the specified date. Please try to update it instead.'
                        );
                    } else {
                        this.$Message.error('Failed to add request time dispute');
                    }
                    this.isLoading = false;
                });
        },
        async handleGetRequestRecordByAttendanceId(data) {
            return await getRequestRecordByUserAndAttendanceId(
                this.attendanceLogData._id,
                this.attendanceLogData.user._id,
                {
                    requestedStart: timeConversion(moment.utc(data.createdAt).format('DD-MMM-YYYY'), '00:00'),
                }
            );
        },
        async openModal(data, fromShiftTable) {
            console.log(data);
            if (fromShiftTable) {
                this.attendanceLogData = data;
                const result = await this.handleGetRequestRecordByAttendanceId(data);
                console.log('results: ', result);
                this.form = {
                    id: result.data[0]._id,
                    createdAt: moment.utc(data.createdAt).format('DD-MMM-YYYY hh:mm:ss A'),
                    initialStartTime: data.clockInTime ? moment.utc(data.clockInTime).format('hh:mm A') : null,
                    initialEndTime: data.clockOutTime ? moment.utc(data.clockOutTime).format('hh:mm A') : null,
                    requestedStartTime: moment.utc(result.data[0].requestedStart).format('HH:mm'),
                    requestedEndTime: moment.utc(result.data[0].requestedEnd).format('HH:mm'),
                    reason: result.data[0].reason,
                };
            } else {
                this.requestData = data;
                this.form = {
                    id: data._id,
                    createdAt: moment.utc(data.attendanceLog.createdAt).format('DD-MMM-YYYY hh:mm:ss A'),
                    initialStartTime: data.attendanceLog.clockInTime
                        ? moment.utc(data.attendanceLog.clockInTime).format('hh:mm A')
                        : null,
                    initialEndTime: data.attendanceLog.clockOutTime
                        ? moment.utc(data.attendanceLog.clockOutTime).format('hh:mm A')
                        : null,
                    requestedStartTime: moment.utc(data.requestedStart).format('HH:mm'),
                    requestedEndTime: moment.utc(data.requestedEnd).format('HH:mm'),
                    reason: data.reason,
                };
            }

            this.isLoading = false;
            this.showModal = true;
        },

        handleCancel() {
            this.isLoading = false;
            this.showModal = false;
        },

        handleConfirm() {
            this.showModal = false;
        },

        handleReset() {
            this.$refs.form.resetFields();
        },
    },
};
</script>
<style lang="scss">
form.ivu-form.ivu-form-label-left.ivu-form-inline {
    border: unset;
    .ivu-form-item {
        display: inline-block !important;
        margin-right: 10px !important;
        vertical-align: top !important;
    }

    .ivu-form-item-content {
        margin-left: 0 !important;
        display: block !important;
    }
}
.updateRequest .ivu-modal-body {
    padding: 16px;
}
.updateRequest .ivu-modal-footer {
    display: none;
}
.updateRequest .ivu-spin-main {
    justify-content: center;
    display: flex;
}
.update-time-dispute-wrapper {
    .ivu-form-item {
        display: inline-block !important;
        margin-right: 10px !important;
        vertical-align: top !important;
    }

    .ivu-form-item-content {
        margin-left: 0 !important;
        display: block !important;
    }
}
@media screen and (max-width: 767px) {
    .update-time-dispute-wrapper {
        .ivu-form-item {
            margin: 20px 5px;

            .ivu-form-item-content {
                margin-left: 0 !important;
                display: block !important;
            }
        }
    }
}
</style>
