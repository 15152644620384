<template>
    <div>
        <div>
            <Table :columns="tblColumns" :data="tblData" size="small" :loading="isLoading" class="requestTable" />
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { getRequestRecordById } from '@/services/requests.services';
export default {
    props: {
        requestId: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            isLoading: false,
            tblColumns: [
                {
                    title: 'Start',
                    key: 'requestedStart',
                    width: 200,
                    render: (h, params) => {
                        return params.row.requestedData.clockInTime
                            ? h('div', moment(params.row.requestedData.clockInTime).utc().format('hh:mm A'))
                            : h('div', { class: 'hyphenFont' }, '-');
                    },
                },
                {
                    title: 'End',
                    key: 'requestedEnd',
                    width: 200,
                    render: (h, params) => {
                        return params.row.requestedData.clockOutTime
                            ? h('div', moment(params.row.requestedData.clockOutTime).utc().format('hh:mm A'))
                            : h('div', { class: 'hyphenFont' }, '-');
                    },
                },
                {
                    title: 'Total Hours',
                    key: 'requestedHrs',
                    width: 150,
                    render: (h, params) => {
                        return params.row.requestedHrs
                            ? h('div', Number(params.row.requestedHrs).toFixed(0))
                            : h('div', { class: 'hyphenFont' }, '-');
                    },
                },
            ],
            tblData: [],
        };
    },

    watch: {
        requestId(data) {
            this.populateRequestTimeDisputes(data);
        },
    },

    created() {
        this.populateRequestTimeDisputes(this.requestId);
    },

    methods: {
        async populateRequestTimeDisputes(id) {
            this.isLoading = true;
            this.tblData = [];
            getRequestRecordById(id)
                .then((res) => {
                    this.tblData = [res.data];
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get requested time logs');
                    this.isLoading = false;
                    this.tblData = [];
                });
        },
    },
};
</script>

<style lang="scss">
ul.pagination.ivu-page {
    text-align: end;
}
.requestTable i.ivu-icon {
    margin-left: -12.5px;
}
</style>
