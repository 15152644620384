<template>
    <div>
        <Modal
            v-model="showModal"
            draggable
            sticky
            title="Create Time Dispute"
            width="600"
            no-footer
            :closable="closable"
            class="createRequest"
            :before-close="handleReset"
        >
            <Form
                ref="form"
                :model="form"
                :rules="isDisabled ? ruleInline : ruleInlineWithCreatedAt"
                :loading="isLoading"
                label-position="left"
                class="create-time-dispute-wrapper"
            >
                <Row>
                    <Col span="12">
                        <FormItem label="Date" prop="createdAt">
                            <DatePicker
                                v-model="form.createdAt"
                                type="date"
                                :options="options"
                                format="dd-MMM-yyyy"
                                transfer
                                :disabled="isDisabled"
                                :loading="isLoading"
                                @on-change="onDateChange"
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                        <FormItem label="Initial Start Time" prop="initialStartTime">
                            <DatePicker
                                v-model="form.initialStartTime"
                                type="datetime"
                                format="hh:mm A"
                                transfer
                                disabled
                                :loading="isLoading"
                            />
                        </FormItem>
                    </Col>
                    <Col span="8">
                        <FormItem label="Initial End Time" prop="initialEndTime">
                            <DatePicker
                                v-model="form.initialEndTime"
                                type="datetime"
                                format="hh:mm A"
                                transfer
                                disabled
                                :loading="isLoading"
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                        <FormItem label="Requested Start Time" prop="requestedStartTime">
                            <TimePicker
                                v-model="form.requestedStartTime"
                                type="time"
                                placeholder="Select request start time"
                                format="HH:mm"
                                confirm
                                transfer
                            />
                        </FormItem>
                    </Col>
                    <Col span="8">
                        <FormItem label="Requested End Time" prop="requestedEndTime">
                            <TimePicker
                                v-model="form.requestedEndTime"
                                type="time"
                                placeholder="Select request end time"
                                format="HH:mm"
                                confirm
                                transfer
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col span="8">
                        <FormItem label="Reason for request (optional)">
                            <Input
                                v-model="form.reason"
                                type="textarea"
                                :autosize="{ minRows: 2, maxRows: 5 }"
                                placeholder="Enter something..."
                                style="width: 400px"
                            />
                        </FormItem>
                    </Col>
                </Row>
            </Form>
            <div>
                <Button class="pt-btn-2" style="margin-right: 10px" @click="handleCancel"> CANCEL </Button>
                <Button class="pt-btn-3" :loading="isLoading" @click="handleAddTimeDispute"> SUBMIT </Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import moment from 'moment';
import { timeConversion } from '../../../../../utility/helper';
import { getRequestRecordByUserAndAttendanceId, postTimeDispute } from '@/services/requests.services';
import { getPaginatedAttendanceRecords } from '@/services/attendancelogs.services';

export default {
    data() {
        const validateCreatedAt = (rule, value, callback) => {
            console.log('validate:', value, rule);
            const date = value;
            if ((date && date == '  ') || !date) {
                return callback(new Error('Please fill in date'));
            } else {
                getPaginatedAttendanceRecords(
                    this.userId,
                    {
                        ...{
                            clockInTime: {
                                $gte: timeConversion(date, '00:00'),
                                $lte: timeConversion(date, '23:59'),
                            },
                        },
                    },
                    this.currentPage,
                    15
                )
                    .then((res) => {
                        if (res.data.data.length > 0) {
                            getRequestRecordByUserAndAttendanceId(this.attendanceLogData._id, this.userId, {
                                requestedStart: timeConversion(moment(date).format('DD-MMM-YYYY'), '00:00'),
                            })
                                .then((result) => {
                                    if (result.data.length > 0) {
                                        return callback(
                                            new Error('You already have a request in progress with this date')
                                        );
                                    } else {
                                        return callback();
                                    }
                                })
                                .catch(() => {
                                    return callback(new Error('Please fill in date'));
                                });
                        } else {
                            return callback(new Error('You have no attendance record with this date'));
                        }
                    })
                    .catch(() => {
                        return callback(new Error('Please fill in date'));
                    });
            }
        };
        return {
            attendanceLogData: null,
            isLoading: false,
            options: null,
            closable: false,
            form: {
                createdAt: null,
                initialStartTime: null,
                initialEndTime: null,
                requestedStartTime: null,
                requestedEndTime: null,
                reason: '',
            },
            ruleInline: {
                requestedStartTime: [
                    {
                        required: true,
                        message: 'Please fill in start time',
                        trigger: 'change',
                    },
                ],
                requestedEndTime: [
                    {
                        required: true,
                        message: 'Please fill in end time',
                        trigger: 'change',
                    },
                ],
            },
            ruleInlineWithCreatedAt: {
                createdAt: [
                    {
                        validator: validateCreatedAt,
                        trigger: 'change',
                    },
                ],
                requestedStartTime: [
                    {
                        required: true,
                        message: 'Please fill in start time',
                        trigger: 'change',
                    },
                ],
                requestedEndTime: [
                    {
                        required: true,
                        message: 'Please fill in end time',
                        trigger: 'change',
                    },
                ],
            },
            isDisabled: false,
            showModal: false,
            userId: null,
        };
    },
    created() {
        this.options = {
            disabledDate(date) {
                const isCurrentDate = date && moment(date).format('DD-MMM-YYYY') == moment().format('DD-MMM-YYYY');
                return isCurrentDate ? false : date && date.valueOf() > Date.now() - 86400000;
            },
        };
    },
    methods: {
        async handleAddTimeDispute() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.addTimeDispute();
                } else {
                    this.$Message.error('Fill in the required fields');
                }
            });
        },

        addTimeDispute() {
            this.isLoading = true;
            const initialDate = this.form.createdAt;
            const startTime = this.form.requestedStartTime;
            const endTime = this.form.requestedEndTime;

            const data = {
                requestedStart: moment(timeConversion(initialDate, startTime)).utc().format(),
                requestedEnd: moment(timeConversion(initialDate, endTime)).utc().format(),
                attendanceLog: this.attendanceLogData._id,
                user: this.attendanceLogData.user._id,
                reason: this.form.reason ? this.form.reason : '',
            };
            console.log('submitted: ', data);
            postTimeDispute(data)
                .then(() => {
                    this.$Message.success('Request time dispute successfully added');
                    this.isLoading = false;
                    this.$emit('create');
                    this.showModal = false;
                    this.$refs.form.resetFields();
                })
                .catch((err) => {
                    if (
                        err.data.statusCode === 400 &&
                        err.data.message === 'Request start time value should not be ahead/or equal to the end time'
                    ) {
                        this.$Message.error('Request start time value should not be ahead/or equal to the end time');
                    } else if (
                        err.data.statusCode === 400 &&
                        err.data.message ===
                            'You`ve already requested time dispute for the specified date. Please try to update it instead.'
                    ) {
                        this.$Message.error(
                            'You`ve already requested time dispute for the specified date. Please try to update it instead.'
                        );
                    } else {
                        this.$Message.error('Failed to add request time dispute');
                    }
                    this.isLoading = false;
                });
        },

        openModal(data, fromShiftTable) {
            console.log(data);
            if (fromShiftTable) {
                this.attendanceLogData = data;
                this.form = {
                    createdAt: moment.utc(data.createdAt).format('DD-MMM-YYYY hh:mm:ss A'),
                    initialStartTime: data.clockInTime ? moment.utc(data.clockInTime).format('hh:mm A') : null,
                    initialEndTime: data.clockOutTime ? moment.utc(data.clockOutTime).format('hh:mm A') : null,
                };
            } else {
                this.userId = data.userId;
            }

            this.isDisabled = !!fromShiftTable;
            this.isLoading = false;
            this.showModal = true;
        },

        onDateChange(date) {
            console.log(date);
            this.isLoading = true;
            if (!this.isDisabled && date && date != '  ') {
                let data = null;
                getPaginatedAttendanceRecords(
                    this.userId,
                    {
                        ...{
                            clockInTime: {
                                $gte: timeConversion(date, '00:00'),
                                $lte: timeConversion(date, '23:59'),
                            },
                        },
                    },
                    this.currentPage,
                    15
                )
                    .then((res) => {
                        if (res.data.data.length) {
                            data = res.data.data[0];
                            this.attendanceLogData = data;
                            this.$refs.form.resetFields();
                            this.form = {
                                createdAt: date,
                                initialStartTime: data.clockInTime
                                    ? moment.utc(data.clockInTime).format('hh:mm A')
                                    : null,
                                initialEndTime: data.clockOutTime
                                    ? moment.utc(data.clockOutTime).format('hh:mm A')
                                    : null,
                            };
                        }
                        this.isLoading = false;
                    })
                    .catch((err) => {
                        this.$Message.error('Failed to get attendance logs');
                        this.isLoading = false;
                        console.log(err);
                    });
            }
        },

        handleCancel() {
            this.isLoading = false;
            this.showModal = false;
            this.$refs.form.resetFields();
        },

        handleConfirm() {
            this.showModal = false;
        },

        handleReset() {
            this.$refs.form.resetFields();
        },
    },
};
</script>
<style lang="scss">
form.ivu-form.ivu-form-label-left.ivu-form-inline {
    border: unset;
    .ivu-form-item {
        display: inline-block !important;
        margin-right: 10px !important;
        vertical-align: top !important;
    }

    .ivu-form-item-content {
        margin-left: 0 !important;
        display: block !important;
    }
}
.createRequest .ivu-modal-body {
    padding: 16px;
}
.createRequest .ivu-modal-footer {
    display: none;
}
.createRequest .ivu-spin-main {
    justify-content: center;
    display: flex;
}
.create-time-dispute-wrapper {
    .ivu-form-item {
        display: inline-block !important;
        margin-right: 10px !important;
        vertical-align: top !important;
    }

    .ivu-form-item-content {
        margin-left: 0 !important;
        display: block !important;
    }
}

@media screen and (max-width: 767px) {
    .create-time-dispute-wrapper {
        .ivu-form-item {
            margin: 20px 5px;

            .ivu-form-item-content {
                margin-left: 0 !important;
                display: block !important;
            }
        }
    }
}
</style>
