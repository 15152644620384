<template>
    <div>
        <Modal
            v-model="showModal"
            draggable
            sticky
            title="View Time Dispute"
            width="600"
            no-footer
            :closable="closable"
            class="viewTimeDispute"
            :before-close="handleReset"
        >
            <Row justify="start" align="top">
                <Col span="15">
                    <div v-if="details">
                        <Row>
                            <Col span="8">
                                <span>Date</span>
                            </Col>
                            <Col v-if="mainRole" span="8">Employee</Col>
                            <Col span="8">Status</Col>
                        </Row>
                        <Row style="margin-bottom: 50px">
                            <Col span="8">
                                <span>{{ details.dateRequested }}</span>
                            </Col>
                            <Col v-if="mainRole" span="8">
                                <span>{{ details.employee }}</span>
                            </Col>
                            <Col span="8"
                                ><div :class="details.className">{{ details.statLabel }}</div></Col
                            >
                        </Row>
                        <Row>
                            <Col span="8"><span>Initial Time</span></Col>
                        </Row>
                        <Row style="margin-bottom: 50px">
                            <AttendanceLogTable :request-id="details._id" />
                        </Row>
                        <Row>
                            <Col span="8"><span>Requested Time</span></Col>
                        </Row>
                        <Row style="margin-bottom: 50px">
                            <RequestTimeDisputeTable :request-id="details._id" />
                        </Row>
                        <Row>
                            <Col span="8"><span>Reason</span></Col>
                        </Row>
                        <Row style="margin-bottom: 50px">
                            <Col span="8"
                                ><span>{{ details.reason }}</span></Col
                            >
                        </Row>
                        <div class="text-left">
                            <Button
                                v-if="mainRole && isPending"
                                class="pt-btn-4"
                                :loading="isLoading"
                                @click="handleRequest('approved')"
                            >
                                APPROVE
                            </Button>
                            <Button
                                v-if="mainRole && isPending"
                                class="pt-btn-1"
                                :loading="isLoading"
                                @click="handleRequest('rejected')"
                            >
                                REJECT
                            </Button>
                            <Button class="pt-btn-2" :loading="isLoading" @click="handleCancel"> CLOSE </Button>
                        </div>
                    </div>
                    <p v-else class="text-center" style="width: 600px">No Data</p>
                </Col>
            </Row>
        </Modal>
    </div>
</template>
<script>
import moment from 'moment';
import AttendanceLogTable from '@/components/Requests/Dev/TimeDispute/AttendanceLogTable';
import RequestTimeDisputeTable from '@/components/Requests/Dev/TimeDispute/RequestTimeDisputeTable';
import { getRequestRecordByUserAndAttendanceId, requestProcess } from '@/services/requests.services';
import { timeConversion } from '../../../../../utility/helper';
export default {
    components: { AttendanceLogTable, RequestTimeDisputeTable },
    data() {
        return {
            isLoading: false,
            details: null,
            showModal: false,
            closable: false,
            attendanceId: null,
            userId: null,
            mainRole: localStorage.getItem('mainRole') === 'admin',
            isPending: false,
        };
    },
    methods: {
        async handleGetRequestRecordByAttendanceId(data) {
            return await getRequestRecordByUserAndAttendanceId(this.attendanceId, this.userId, {
                requestedStart: timeConversion(moment.utc(data.createdAt).format('DD-MMM-YYYY'), '00:00'),
            });
        },
        async openModal(data, fromShiftTable) {
            this.attendanceId = fromShiftTable ? data._id : data.attendanceLog._id;
            this.userId = data.user._id;
            try {
                if (fromShiftTable) {
                    const result = await this.handleGetRequestRecordByAttendanceId(data);
                    const status = this.handleStatusLabel(result.data[0]);
                    this.details = {
                        _id: result.data[0]._id,
                        employee: data.user.name,
                        dateRequested: moment(result.data[0].requestedStart).utc().format('DD-MMM-yyyy'),
                        className: status.className,
                        statLabel: status.label,
                        reason: result.data[0].reason,
                    };
                    this.isPending = result.data[0].status && result.data[0].status.includes('pending');
                } else {
                    const status = this.handleStatusLabel(data);
                    this.details = {
                        _id: data._id,
                        employee: data.user.name,
                        dateRequested: moment(data.requestedStart).utc().format('DD-MMM-yyyy'),
                        className: status.className,
                        statLabel: status.label,
                        reason: data.reason,
                    };
                    this.isPending = data.status && data.status.includes('pending');
                }
                this.showModal = true;
            } catch (err) {
                this.$Message.error('Request record not found');
            }
        },

        handleStatusLabel(data) {
            const status = data.status;
            let label = '',
                className = 'statusLabel';
            const upperCaseStats = status.toString().toUpperCase();
            switch (status.toString().trim()) {
                case 'pending':
                    label = upperCaseStats;
                    className = `${className} warning`;
                    break;
                case 'rejected':
                    label = upperCaseStats;
                    className = `${className} danger`;
                    break;
                case 'approved':
                    label = upperCaseStats;
                    className = `${className} success`;
                    break;

                default:
                    break;
            }
            return { label, className };
        },

        handleCancel() {
            this.showModal = false;
        },

        handleConfirm() {
            this.showModal = false;
        },
        handleReset() {
            this.showModal = false;
        },

        handleRequest(status) {
            this.isLoading = true;
            const data = {
                id: this.details._id,
                status: status,
            };
            requestProcess(data)
                .then((res) => {
                    if (res && res.data.success) {
                        this.$Message.success(`Request has been ${status}`);
                        this.isLoading = false;
                        this.$emit('create');
                        this.showModal = false;
                    }
                })
                .catch((err) => {
                    if (err.data.statusCode === 400 && err.data.message === 'request not found') {
                        this.$Message.error('Request not found');
                    } else if (err.data.statusCode === 403 && err.data.message === 'request already processed') {
                        this.$Message.error(
                            'You`ve already processed this request time dispute. Please do check it again.'
                        );
                    } else {
                        this.$Message.error('Failed to process request time dispute');
                    }
                    this.isLoading = false;
                });
        },
    },
};
</script>
<style>
.viewTimeDispute .ivu-modal-body {
    padding: 16px;
}
.viewTimeDispute .ivu-modal-footer {
    display: none;
}
.viewTimeDispute .ivu-spin-main {
    justify-content: center;
    display: flex;
}
.viewTimeDispute button {
    font-weight: bold;
    margin-left: 10px;
}
.statusLabel {
    display: inline-block;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
    vertical-align: middle;
    max-width: 250px;
    padding: 5px;
    text-align: center;
}
.success {
    background: #49e97e;
    font-weight: bold;
}
.warning {
    background: #ffc107;
    font-weight: bold;
}
.danger {
    background: #e9495d;
    font-weight: bold;
}
</style>
