import moment from 'moment';

// month May(05)
// output: ["05/01","05/02",...05/31]
export const generateDaysForLabels = function (year, month) {
    const monthIndex = month - 1; // 0..11 instead of 1..12
    const date = new Date(year, monthIndex, 1);
    const result = [];
    while (date.getMonth() == monthIndex) {
        const dateFormatted = moment(new Date(date.getTime()).toISOString()).format('MM/DD');
        result.push(dateFormatted);

        date.setDate(date.getDate() + 1);
    }
    return result;
};

export const checkIfBeforeEightAM = moment().isBefore(moment('08:00:00', 'HH:mm:ss'), 'hours');

export const startOfDate = function (date) {
    return moment(`${moment(date).format('yyyy-MM-DD')}T00:00:00.000Z`)
        .utc()
        .format();
};

export const endOfDate = function (date) {
    return moment(`${moment(date).format('yyyy-MM-DD')}T23:59:59.000Z`)
        .utc()
        .format();
};

export const checkIfFirstAttendancePeriod = function () {
    const today = moment().format('DD');
    return parseInt(today) >= 1 && parseInt(today) <= 15;
};

export const timeConversion = function (date, time) {
    const timeString = time.toString().split(' ');
    const finalTime = timeString[0].split(':');
    return moment(date)
        .set({ hour: Number(finalTime[0]), minute: Number(finalTime[1]) })
        .format('yyyy-MM-DDTHH:mm:ss');
};
