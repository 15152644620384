import { axiosInstance, getAuthorizationHeaders } from './base';

export function getAsset(id) {
    return axiosInstance.get(`nfts/${id}`, getAuthorizationHeaders());
}

export function getAssets(queryParams) {
    const queryString = Object.keys(queryParams)
        .filter((key) => queryParams[key] !== null)
        .reduce((acc, curr) => (acc += `${curr}=${queryParams[curr]}&`), '')
        .slice(0, -1);

    return axiosInstance.get(`nfts?${queryString}`, getAuthorizationHeaders());
}

export function getCategories() {
    return axiosInstance.get(`nfts/assets/categories`, getAuthorizationHeaders());
}

export function signatureRequestToMoralis(data) {
    return axiosInstance.post(`nfts/request-signature`, data, getAuthorizationHeaders());
}

export function signatureVerificationToMoralis(data) {
    return axiosInstance.post(`nfts/verify-signature`, data, getAuthorizationHeaders());
}

export function verifyUserConnectedWallet(data) {
    return axiosInstance.post(`nfts/verify-wallet`, data, getAuthorizationHeaders());
}

export function createAsset(data) {
    return axiosInstance.post('nfts', data, getAuthorizationHeaders());
}

export function updateAsset(id, data) {
    return axiosInstance.patch(`nfts/${id}`, data, getAuthorizationHeaders());
}

export function mintAsset(id) {
    return axiosInstance.patch(`nfts/${id}/mint`, {}, getAuthorizationHeaders());
}

export function deleteAsset(id) {
    return axiosInstance.delete(`nfts/${id}`, getAuthorizationHeaders());
}

export function transferNft(id, data) {
    return axiosInstance.patch(`nfts/${id}/transfer`, data, getAuthorizationHeaders());
}

export function sendEmailToNewOwner(data) {
    return axiosInstance.post(`nfts/transactions/${data.transactionId}/send-email`, data, getAuthorizationHeaders());
}
