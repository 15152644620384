import { axiosInstance, getAuthorizationHeaders } from '@/services/base';

const roleUrlString = localStorage.getItem('mainRole') === 'admin' ? `admin/requests` : `requests`;

export function postTimeDispute(data) {
    const urlString = `requests`;
    return axiosInstance.post(urlString, data, getAuthorizationHeaders());
}
export function cancelLeave(id) {
    return axiosInstance.post(`users/leaves/${id}/cancel`, null , getAuthorizationHeaders());
}
export function changeLeave({id, data}) {
    return axiosInstance.put(`users/leaves/${id}/change`, data , getAuthorizationHeaders());
}


export function getRequestRecordById(id) {
    const urlString = `requests/${id}`;
    return axiosInstance.get(urlString, getAuthorizationHeaders());
}

export function getRequestRecordByUserAndAttendanceId(attendanceId, userId, query) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    const urlString = `requests/${userId}/${attendanceId}`;
    return axiosInstance.get(urlString, { params: { requestedStart: query.requestedStart }, headers });
}

export function updateTimeDispute(id, data) {
    const urlString = `requests/${id}`;
    return axiosInstance.patch(urlString, data, getAuthorizationHeaders());
}

export function deleteTimeDispute(id) {
    const urlString = `requests/${id}`;
    return axiosInstance.delete(urlString, getAuthorizationHeaders());
}

export function getPaginatedRequestRecordsByUser(userId, filter, page = 1, limit = 1000) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    const urlString = `/paginated/${userId}/records`;
    return axiosInstance.get(roleUrlString + urlString, {
        params: { filter: filter, limit: limit, page: page, sort: { createdAt: -1 } },
        headers,
    });
}
/*Admin Api Endpoints*/
export function requestProcess(data) {
    const urlString = `/process`;
    return axiosInstance.post(roleUrlString + urlString, data, getAuthorizationHeaders());
}
export function adminCancelLeave(id) {
    return axiosInstance.post(`admin/leaves/${id}/cancel`, null, getAuthorizationHeaders());
}

export function getAdminPaginatedRequestsByUser(filter, page = 1, limit = 1000) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    const urlString = `/list`;
    return axiosInstance.get(roleUrlString + urlString, {
        params: { filter: filter, limit: limit, page: page },
        headers,
    });
}

export function requestLeaveProcess(data) {
    return axiosInstance.post('admin/leaves/process', data , getAuthorizationHeaders());
}

